import { useLocation } from '@reach/router';
import classd from 'classd';
import { T, always, cond, dec, equals, isNil } from 'ramda';
import React, {
  FC,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import { Colors } from '../utils/style-utils';

interface AccordionProps {
  className?: string;
  defaultOpenIndex?: number;
  size?: 'sm' | 'lg';
  autoFocus?: boolean;
}

interface AccordionItemProps {
  children: ReactNode;
  onToggle?: () => void;
  open?: boolean;
  title: string | ReactElement;
  index?: number;
  autoFocus?: boolean;
}

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  position: relative;

  &:last-child {
    border-bottom: 0 none;
  }
`;

const Item = styled.a`
  background-color: transparent;
  border: 0 none;
  position: relative;
  padding: 27px 10px 27px 70px;
  width: 100%;
  text-align: left;

  ${Container}.sm & {
    padding: 10px 5px 10px 50px;
  }
`;

const ItemLabel = styled.span`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  margin: 0;
  height: 36px;
  color: ${Colors.Blue900};

  ${ItemContainer}.open & {
    color: ${Colors.Blue500};
  }

  ${Container}.sm & {
    font-size: 14px;
  }
`;

const ItemIcon = styled.span`
  position: absolute;
  top: 32px;
  left: 22px;

  ${Container}.sm & {
    top: 10px;
    left: 12px;
  }

  ${ItemContainer}.open & {
    top: 25px;
    left: 17px;
  }

  ${Container}.sm ${ItemContainer}.open & {
    top: 5px;
    left: 7px;
  }
`;

const ItemChild = styled.div`
  display: none;
  padding: 26px 70px 48px;

  ${ItemContainer}.open & {
    display: block;
  }

  ${Container}.sm & {
    padding: 12px 20px 18px;
  }
`;

const getInitialState = cond<number, number>([
  [isNil, always(-1)],
  [equals(-1), always(-1)],
  [T, dec],
]);

export const Accordion: FC<AccordionProps> = ({
  children,
  defaultOpenIndex,
  className,
  size,
  autoFocus,
}) => {
  const location = useLocation();
  const [openIndex, setOpenIndex] = useState(() => getInitialState(defaultOpenIndex as number));

  useEffect(() => {
    const index = Number(location.hash.substring(1));
    if (index !== openIndex && autoFocus) {
      setOpenIndex(index - 1);
    }
  }, [location.hash]);

  return (
    <Container className={classd`${className} ${size}`}>
      {React.Children.toArray(children)
        .filter((child) => isValidElement(child))
        .map((child, index) => {
          const selected = openIndex === index;
          // @ts-ignore
          return cloneElement(child, {
            index,
            onToggle: () => (selected ? setOpenIndex(-1) : setOpenIndex(index)),
            open: selected,
            autoFocus,
          });
        })}
    </Container>
  );
};

Accordion.defaultProps = {
  className: '',
  defaultOpenIndex: -1,
  size: 'lg',
  autoFocus: true,
};

export const AccordionItem: FC<AccordionItemProps> = ({
  open,
  onToggle,
  title,
  children,
  index,
  autoFocus,
}) => {
  const readableIndex = Number(index) + 1;

  useEffect(() => {
    if (open && autoFocus) {
      const el = document.querySelector(`.accordion-item[href='#${readableIndex}']`);
      if (el) el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [open]);

  return (
    <ItemContainer className={classd`${{ open }}`}>
      <Item
        onClick={onToggle}
        className="accordion-item inline-block"
        {...(autoFocus ? { href: `#${readableIndex}` } : {})}>
        <ItemIcon>
          {open ? (
            <svg width="37" height="36" viewBox="0 0 37 36" fill="none">
              <path
                d="M11.7673 11.9808L12.8031 10.945C12.8951 10.8529 12.9872 10.8529 13.0793 10.945L25.2327 23.0984C25.3247 23.1905 25.3247 23.2825 25.2327 23.3746L24.1969 24.4104C24.1048 24.5025 24.0127 24.5025 23.9207 24.4104L11.7673 12.257C11.6752 12.1649 11.6752 12.0729 11.7673 11.9808Z"
                fill="black"
              />
              <path
                d="M12.0435 22.8221L23.6445 11.2211C23.7365 11.1291 23.8286 11.1291 23.9207 11.2211L24.9565 12.2569C25.0486 12.349 25.0486 12.4411 24.9565 12.5331L13.3555 24.1341C13.2634 24.2262 13.1714 24.2262 13.0793 24.1341L12.0435 23.0983C11.9514 23.0062 11.9514 22.9142 12.0435 22.8221Z"
                fill="black"
              />
            </svg>
          ) : (
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M11.7676 3.71094H13.2324C13.3626 3.71094 13.4277 3.77604 13.4277 3.90625V21.0938C13.4277 21.224 13.3626 21.2891 13.2324 21.2891H11.7676C11.6374 21.2891 11.5723 21.224 11.5723 21.0938L11.5723 3.90625C11.5723 3.77604 11.6374 3.71094 11.7676 3.71094Z"
                fill="black"
              />
              <path
                d="M4.29688 11.5723L20.7031 11.5723C20.8333 11.5723 20.8984 11.6374 20.8984 11.7676V13.2324C20.8984 13.3626 20.8333 13.4277 20.7031 13.4277H4.29688C4.16667 13.4277 4.10156 13.3626 4.10156 13.2324V11.7676C4.10156 11.6374 4.16667 11.5723 4.29688 11.5723Z"
                fill="black"
              />
            </svg>
          )}
        </ItemIcon>
        <ItemLabel>{title}</ItemLabel>
      </Item>
      <ItemChild>{children}</ItemChild>
    </ItemContainer>
  );
};

AccordionItem.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onToggle: () => {},
  open: false,
};
