import { RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Dialog from 'rc-dialog';
import React, { FC, useState } from 'react';
import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

import { Accordion, AccordionItem } from '../components/Accordion';
import { Anchor } from '../components/Anchor';
import { ListItem, Ol, Ul } from '../components/List';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { TypesTable } from '../components/TypesTable';
import { H1, H2, H4, Typography } from '../components/Typography';
import { Device } from '../utils/media-queries-utils';
import { Colors } from '../utils/style-utils';

const Container = styled.div`
  padding: 100px 220px;

  @media ${Device.laptopM} {
    padding: 100px 150px;
  }

  @media ${Device.laptop} {
    padding: 100px 75px;
  }

  @media ${Device.tablet} {
    padding: 100px 30px;
  }

  @media ${Device.mobileS} {
    padding: 100px 30px;
  }
`;

const IndentedP = styled.p`
  text-indent: 30px;
  margin-bottom: 10px;
`;

const Faq: FC<RouteComponentProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const staticData = useStaticQuery(graphql`
    query {
      faqsSection21: file(relativePath: { eq: "faqs-section-2-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection22: file(relativePath: { eq: "faqs-section-2-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection23: file(relativePath: { eq: "faqs-section-2-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection24: file(relativePath: { eq: "faqs-section-2-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection31: file(relativePath: { eq: "faqs-section-3-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <PublicRoute {...props}>
      <Container>
        <SEO title="FAQs" />
        <H1 className="mb-24" color="#051392">
          ARIZONA COURT OF APPEALS, DIVISION TWO HYPERCITE® COMPREHENSIVE USER GUIDE
        </H1>

        <H2 className="mb-4">Introduction</H2>

        <Typography className="mb-4">Hello, and let us introduce you to HyperCite®.</Typography>

        <Typography className="mb-4">
          In the Arizona Court of Appeals, Second District, the Rules of Procedure require filers to
          hyperlink any citations to the record on appeal. (Rule 13.1(a); Rule 31.11(a).)
        </Typography>

        <Typography className="mb-4">
          Until now, Second District filers have had to transform their textual citations to the
          record into hyperlinks by hand, at considerable time and expense. This existing method is
          instructed{' '}
          <Anchor href="https://www.appeals2.az.gov/e-filer/usingHyperlinks.pdf">here</Anchor>.
          However, the by-hand linking method no longer remains the status quo.
        </Typography>

        <Typography className="mb-4">
          HyperCite® is a new, web-based software, that automatically creates hyperlinks between
          textual citations in a filer’s parent document (e.g. brief, petition, motion, etc.) to the
          document/item cited in the record on appeal. The record on appeal for HyperCite® linking
          purposes does not only include documents filed in the superior court (ROA). HyperCite®
          hyperlinks citations to documents/items filed in the Court of Appeals as well. These
          include transcripts, prior briefing and motions, extension requests, and etc.
        </Typography>

        <Typography className="mb-4">
          Instructions, below, expand upon the information located on our home page. Please take the
          time to read these instructions to ensure the parent document is linked as intended. If
          instructions aren’t followed anomalous links can occur.
        </Typography>

        <Typography className="mb-4">
          There are three steps to HyperCite® correctly in the instructions below. They are:
        </Typography>

        <Ul listStyle="latin" className="pl-6">
          <ListItem>
            <Typography bold>Step #1: Write it right so that HyperCite® links it right</Typography>
            <Ul listStyle="roman">
              <ListItem>
                <Typography bold>ROA (Record on Appeal) citations</Typography>
              </ListItem>
              <ListItem>
                <Typography bold>
                  Briefing, transcript, and other common documents filed in the Court of Appeals
                  that are not part of the ROA
                </Typography>
              </ListItem>
              <ListItem>
                <Typography bold>
                  <i>Less common</i> documents filed in the Court of Appeals that are not part of
                  the ROA / catch all
                </Typography>
              </ListItem>
              <ListItem>
                <Typography bold>
                  Subsequent citations to the same Court of Appeals documents: “COA-<i>Id</i>.”
                </Typography>
              </ListItem>
            </Ul>
          </ListItem>
          <ListItem>
            <Typography bold>Step #2: Upload, Process, and Download</Typography>
          </ListItem>
          <ListItem>
            <Typography bold>Step #3: After Download</Typography>
          </ListItem>
          <ListItem>
            <Typography bold>Troubleshooting Errors</Typography>
          </ListItem>
        </Ul>

        <Typography className="mb-4">
          We hope the following assists you on your HyperCite® journey.
        </Typography>

        <Typography className="mb-4">Happy hyperlinking!</Typography>

        <Typography className="mb-4">-The HyperCite® Team</Typography>

        <H2 className="mb-4">Instructions</H2>

        <Accordion defaultOpenIndex={Number(props.location?.hash.substring(1))}>
          <AccordionItem title="Step #1: Write it right so HyperCite® links it right">
            <Typography className="pl-6 mb-4" bold>
              i. ROA citations
            </Typography>

            <Typography className="mb-4">Continue to cite to the ROA the same as usual.</Typography>

            <Typography className="mb-4">
              Here is an example of citing to the first item appearing in the ROA.
            </Typography>

            <div className="flex items-center justify-center pb-6">
              <div className="w-full">
                <Img fluid={staticData.faqsSection21.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="mb-4">
              <b>Remember</b>, for the Record on Appeal, cite the number found immediately to the
              left of the document title (See the red arrow).
            </Typography>

            <Typography className="mb-4">
              Acceptable citations include, but are not limited to:
            </Typography>

            <Ul listStyle="none">
              <ListItem>
                <Typography>[ROA 1 ep 1-2, 15; ROA 2 ep 1, 3; ROA 20]</Typography>
              </ListItem>
              <ListItem>
                <Typography>(R.O.A. 1 ep 3-10, 17; ROA 2 ep 1, 3; ROA 20)</Typography>
              </ListItem>
              <ListItem>
                <Typography>ROA 1 ep 10-30, 42, 102; ROA 2 ep 1, 3; ROA 20 at 24-67</Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  [R.O.A. 1 ep 1-2, 15; R.O.A. 2 ep 1, 3; R.O.A. 20 pp. 100, 102]
                </Typography>
              </ListItem>
            </Ul>

            <Typography className="mb-4">
              Note: If you would like to construct string cites containing the ROA, as well as cites
              to additional sources, such as transcripts, other briefing, cases, etc, HyperCite®
              requires a semi-colon between each source (red font): [ROA 14 ep 2-6, 50, 75-78
              <span className="text-lg text-red-600 font-black">;</span> R.T. 10/02/2018 ep 2
              <span className="text-lg text-red-600 font-black">;</span> RB 03/10/2020 ep 50-51, 63
              <span className="text-lg text-red-600 font-black">;</span> Jones v. Jones
              <span className="text-lg text-red-600 font-black">;</span> 05/01/2019 Order re Brief
              Extension]
            </Typography>

            <Typography className="mb-4">
              For subsequent citations to a previous citation, see Step #1, section (iv) below.
            </Typography>

            <Typography className="pl-6 mb-4" bold>
              ii. Briefing, transcript, and other common documents filed in the Court that are not
              part of the ROA (Record on Appeal)
            </Typography>

            <Typography className="mb-4">
              Over time, filers have adopted acronyms to refer to the most filed documents in the
              Court of Appeals. These acronyms benefit automated hyperlinking as well. You may
              already know some of the following acronyms, but there are some you may not be
              familiar with. Use these acronyms with dates to ensure your filing is linked
              correctly. Documents filed in the Court that are not contained within the ROA (Record
              on Appeal) do not possess ROA numbers. The red box in Fig. 1 demarks non-ROA
              documents.
            </Typography>

            <div className="flex items-center justify-center pb-6">
              <div className="w-full">
                <Img fluid={staticData.faqsSection22.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="mb-4">
              <b>Remember</b>, since there is no number in the column directly next to the non-ROA
              documents filed in the Court of Appeals, do not create citations to the very left most
              column numbers (e.g. 33, 34, 35, 36, 37 and etc.)!
            </Typography>

            <Typography className="mb-4">
              To cite to the most common non-ROA documents correctly, please employ the citation
              conventions indicated below. A refresher on electronic page numbers (ep #(s).) may be
              found{' '}
              <Anchor href="https://www.appeals2.az.gov/e-filer/usingHyperlinks.pdf">here</Anchor>.
            </Typography>

            <Typography className="mb-4" bold>
              Common Briefs (Requires date, unless subsequently cited as COA-<i>Id</i>. after first
              full cite and no intervening COA citations)
            </Typography>

            <table className="table-auto border-collapse border border-gray-300 mb-4">
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Opening Brief</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>OB, O.B. or Open. Br. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Answering Brief</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>AB, A.B. or Ans. Br. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Reply Brief </Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>RB, R.B. or Rep. Br. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Opening Brief on Cross Appeal</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>XOB or X.O.B. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Answering Brief on Cross Appeal</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>XAB or X.A.B. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Reply Brief on Cross Appeal</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>XRB or X.R.B. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Answering Brief & Opening Brief on Cross-Appeal</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>XAAB or X.A.A.B. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Appellees Combined Answering Brief & Cross-Opening Brief</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>AOBA or A.O.B.A. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Anders Brief </Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>Anders Br. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Supplemental of any brief </Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>
                    Add “Suppl.” prefix (e.g. Sup. OB, Sup. RB, or etc.) + [MM/DD/YR] ep #(s)
                  </Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Amended of any brief </Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>
                    Add “Am.” as a prefix (e.g. Am. OB, Am. AAB, or etc.) + [MM/DD/YR] ep #(s)
                  </Typography>
                </td>
              </tr>
            </table>

            <Typography className="mb-4" bold>
              Transcripts (Requires date, unless subsequently cited as COA-<i>Id</i>. after first
              full cite and no intervening COA citations)
            </Typography>

            <table className="table-auto border-collapse border border-gray-300 mb-4 w-full">
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Transcripts*</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>
                    RT or R.T. + [MM/DD/YR.]
                    <br />
                    (e.g. R.T. 09/05/2008)
                    <br />
                    <br />
                    <span className="text-red-500">
                      * Do not cite the filed date. Instead, cite the date of hearing
                    </span>
                  </Typography>
                </td>
              </tr>
            </table>

            <div className="flex items-center justify-center pb-6">
              <div className="w-full">
                <Img fluid={staticData.faqsSection23.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="mb-4" bold>
              Common Motions (date always required unless subsequently cited as COA-<i>Id</i>. after
              full cite and no intervening citations)
            </Typography>

            <table className="table-auto border-collapse border border-gray-300 mb-4 w-full">
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Motion for Extension Request</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>Extn. Req. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Motion for Reconsideration</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>Mot. to Recons. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Response to Motion for Reconsideration</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>Resp. Mot. to Recons. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Motion to Expand Record</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>Mot. to Exp. Reco. + [MM/DD/YR] ep #(s)</Typography>
                </td>
              </tr>
            </table>

            <Typography className="mb-4" bold>
              Common Petitions (date required unless subsequently cited as COA-<i>Id</i>. after full
              cite and no intervening COA citations)
            </Typography>

            <table className="table-auto border-collapse border border-gray-300 mb-4 w-full">
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Petition for Review</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>Pet. for Review + [MM/DD/YY] ep #(s)</Typography>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Response to Petition for Review</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>Resp. to Pet. for Review + [MM/DD/YY] ep #(s)</Typography>
                </td>
              </tr>
            </table>

            <Typography className="mb-4">
              <b>Hint:</b> Are you someone who likes to write out the entire document name in the
              first instance and then use the short form acronym thereafter? If so, use a form such
              as Opening Brief, OB [MM/DD/YY] ep __ instead of Opening Brief [MM/DD/YY] ep
              (hereinafter “OB”)
            </Typography>

            <Typography className="mb-4">
              Caution: Although HyperCite® <i>may</i> process your animal style type citations
              (improper form), only best practice is illustrated here. Employ the best practice
              because it 1) ensures correct links and 2), whether using HyperCite® or not, the Court
              finds their instructed practice the easiest to read.
            </Typography>

            <Typography className="pl-6 mb-4" bold>
              iii. Less common documents filed in the Court that are not part of the ROA / catch all
            </Typography>

            <Typography className="mb-4">
              Although less common, you may have occasion to cite documents in your filing that have
              no acquired acronyms. When citing to one of these documents, the Court and HyperCite®
              require you to construct a cite containing &ldquo;the document type and date or the
              document subtype and date&rdquo;. This manner of citation is also a catch all for any
              kind of more common acronym citation that fails for some reason.
            </Typography>

            <Ul listStyle="none">
              <ListItem>
                <Typography>Type + MM/DD/YY</Typography>
              </ListItem>
              <ListItem>
                <Typography>Subtype + MM/DD/YY</Typography>
              </ListItem>
            </Ul>

            <Typography className="mb-4">
              Figure 3 demonstrates where Type, Subtype, and Date can usually be found
            </Typography>

            <div className="flex items-center justify-center pb-6">
              <div className="w-full">
                <Img fluid={staticData.faqsSection24.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="mb-4">
              If you want to cite to the Rule 32 transmittal letter (third from the top), the
              following are appropriate citations:
            </Typography>

            <Ul listStyle="none">
              <ListItem>
                <Typography>(Rule 32 05/06/2019 ep 1-2)</Typography>
              </ListItem>
              <ListItem>
                <Typography>(Transmittal letter 05/06/2019 ep 1-2)</Typography>
              </ListItem>
            </Ul>

            <Typography className="mb-4">
              <b className="text-red-500">Important!</b> – It is critical for you to match your
              citation’s type or subtype text exactly to what appears in the Court’s <i>docket</i>.
            </Typography>

            <Typography className="mb-4">
              Exhaustive list/universe of type and subtype docket entries:{' '}
              <Anchor href="#" onClick={() => setVisible(true)}>
                Click Here
              </Anchor>
            </Typography>

            <Typography className="pl-6 mb-4" bold>
              iv. Subsequent citations to the same documents filed in the Court of Appeals: “COA-
              <i>Id.</i>”
            </Typography>

            <Typography className="mb-4">
              The most significant change you may encounter from your past citation practice, to
              what is required now, is likely subsequent citations to the same document filed in the
              Court of Appeals (COA).
            </Typography>

            <Typography className="mb-4">
              In the past, you wrote subsequent cites to the same COA document as “<i>Id</i>.” A
              change in practice, you must now instead write “COA-<i>Id</i>.” At the same time, you
              must continue to use just “<i>Id</i>.” for subsequent references to a case, statute,
              or other document from outside the Court of Appeals’s docket. The following sample
              shows the practice in action:
            </Typography>

            <div className="font-serif p-2 lg:p-4">
              <IndentedP>
                Appellant Clinton Quin was convicted of two counts of aggravated assault for an
                incident in which he commanded his four dogs to attack a bicyclist. (ROA p. 59.) The
                evidence at trial established the following facts, viewed in the light most
                favorable to support his convictions.{' '}
                <b className="italic text-green-500">
                  (See State v. Tamplin, 195 Ariz. 246, 246, ¶ 2 (App. 1999)
                </b>
              </IndentedP>

              <IndentedP>
                On May 27, 2017, Andrew Rudie was riding his bicycle on a dirt path in Tucson when
                he encountered Quin and his four dogs. (<b className="italic text-green-500">Id.</b>
                ; <span className="text-red-500">R.T. 10/2/18</span>, at 28–31, 56.) The dogs were
                not leashed and Rudie thought that Quin did not have them under control. (
                <b className="text-red-500">
                  COA-<i>Id</i>
                </b>
                . at 32–33.) Quin had his hand up, so Rudie stopped approximately 30 feet away. (
                <b className="text-red-500">
                  COA-<i>Id</i>
                </b>{' '}
                at 32, See also O.B. at p. 10.)
              </IndentedP>

              <IndentedP>
                Rudie raised his hand, ready to strike Quin, but did not hit him. (
                <span className="text-red-500">R.T. 10/2/18</span> at 34.) Rudie decided to leave
                the area, and asked Quin to keep his dogs away from him. (
                <b className="text-red-500">
                  COA-<i>Id</i>
                </b>
                . at 35–36.) Instead, Quin yelled “Get him.” (
                <b className="text-red-500">
                  COA-<i>Id</i>
                </b>
                . at 36.) All four dogs then bit Rudie. (
                <b className="text-red-500">
                  COA-<i>Id</i>
                </b>
                ; O.B. ep. 10-11, 12.)
              </IndentedP>

              <IndentedP>
                After a 2-day trial, a jury found Quin guilty of aggravated assault, (R.T. 10/3/18
                p.m., at 59-50; R.O.A. 54, 55, 57, 59.) After finding that Quin had two prior felony
                convictions, the trial court sentenced him as a repetitive offender. (R.T. 11/20/18,
                at 7; R.O.A. 75.) Quin filed a timely notice of appeal. (R.O.A. 77, 78.) This court
                has jurisdiciton. Article VI, Section 9, and Arizona Revised Statutes §§
                12-120.21(A)(1), 13-4031, and 4033(A)
              </IndentedP>
            </div>

            <Typography className="mb-4">
              The text in the green font, above, demonstrates a continued use of “<i>Id</i>.”,
              without modification, when citing documents contained in research platforms or
              otherwise not contained within the Court’s docket. The case <i>State v. Tamplin</i> is
              not found in the Court’s docket, thus the subsequent cite is “<i>Id</i>.” The same
              would apply to anything else not found in your case number’s docket.
            </Typography>

            <Typography className="mb-4">
              While the text in green exhibits a continued practice the text in red font reveals the
              change when it comes to subsequent references to documents filed in the Court of
              Appeals. The second paragraph reference to “R.T. 10/2/18” is to a Court of Appeals
              Document. Thus, the subsequent citation to “R.T. 10/2/18” is “COA-<i>Id</i>.” In the
              third paragraph, reference to “R.T. 10/2/18” is subsequently cited in the same “COA-
              <i>Id</i>.” manner. The same hold true for OB, AB, and etc. citations.
            </Typography>

            <Typography className="mb-4">
              <b className="text-red-500">Important</b>: You must use .<i>Id</i> and COA-<i>Id</i>.
              correctly in any parent document (e.g. briefs) that contain docket and non-docket
              subsequent citations. Failure to do so will likely lead to adverse results. The
              alternative is to always use a full cite to subsequent COA documents.{' '}
              <span className="underline">
                The Court of Appeals actually prefers you not use <i>Id</i>. cites at all,
                HyperCite® or not (i.e. by hand).
              </span>{' '}
              At the very least, use a short form other than Id. whenever you start a new paragraph.
              Some use Id. for the same citation over multiple pages. The Court respectfully
              requests you not do that.
            </Typography>

            <Typography className="mb-4">
              <b>Note:</b> When you employ “COA-<i>Id</i>.” after a string citation, HyperCite® will
              create a hyperlink to the last Court of Appeals document in the string. For example, a
              COA-<i>Id</i>. appearing after something like (O.B. p. 10-11; ROA 94, 89, 87;{' '}
              <i>State v. Booker</i> 205 Ariz. 267, 273 (App. 2003) would link to ROA 87 since that
              was the last Court of Appeals citation contained within the string.
            </Typography>

            <Typography className="mb-4">
              <b>Trick:</b> Don’t want to write out COA-<i>Id</i>. each time? Try key mapping that
              text to a single key by searching Google for your word processing program version and
              the terms “key mapping.”
            </Typography>
          </AccordionItem>

          <AccordionItem title="Step #2: Upload, Process, and Download">
            <Typography className="mb-4">
              After you have written your parent document (e.g. brief) right, you may submit it to
              HyperCite® for automatic hyperlink generation processing. To do so,
            </Typography>

            <Ol className="pl-6">
              <ListItem>
                <Typography>Save or export your parent document to .pdf format.</Typography>
              </ListItem>
              <ListItem>
                <Typography>Navigate to www.az.hypercite.net</Typography>
              </ListItem>
              <ListItem>
                <Typography>Login</Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Click on “Add PDF file”, then find and select the parent document from your
                  computer for upload
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>Enter the full case id/number</Typography>
              </ListItem>
              <ListItem>
                <Typography>Hit process</Typography>
              </ListItem>
            </Ol>

            <Typography className="mb-4">
              After the few seconds processing takes to complete, HyperCite® will cause your
              internet browser to download the now hyperlinked output (e.g. hyperlinked answering
              brief). If download does not occur automatically, click the link HyperCite® displays
              for manual download.
            </Typography>

            <Typography className="mb-4">
              <b className="text-red-500">Important:</b> Upon processing, HyperCite® will not
              disturb any preexisting bookmarks, links (like those created by West’s drafting
              assistant or similar), or text.
            </Typography>
          </AccordionItem>

          <AccordionItem title="Step #3: After Download">
            <Typography>
              After download, and upon viewing your hyperlinked parent document, check your new
              document for the following: 1) all the citations you intended to hyperlink were indeed
              linked. 2) the links navigate the reader to the proper destination; and 3) there are
              no “error boxes” around any citations (see below troubleshooting explanation for error
              box description).
            </Typography>
          </AccordionItem>

          <AccordionItem title="Troubleshooting Guide">
            <Typography className="mb-4" bold>
              1. HyperCite® did not transform any citations into links.
            </Typography>

            <Typography className="mb-4">
              In the event HyperCite® fails to create any hyperlinks at all, there are four likely
              causes:
            </Typography>

            <Typography className="mb-4">
              You may have uploaded for processing a PDF on which you did not perform Optical
              Character Recognition (OCR). This problem usually arises where you manually scan a
              document rather than saving or exporting from a word processing software (e.g. Word or
              Word Perfect). An easy way to tell whether you need to perform OCR on your document or
              not is to press CTRL-F on your keyboard so you can search for a word you know exists
              within your document. Should the search fail to find a word you know exists, you did
              not OCR your document.
            </Typography>

            <Typography className="mb-4">
              <b>Remedy:</b> OCR your parent document in a PDF program. Then process through
              HyperCite® again.
            </Typography>

            <Typography className="mb-4">
              You may have entered the wrong the case number, the format is incorrect, or the number
              contains an error
            </Typography>

            <Typography className="mb-4">
              <b>Remedy:</b> Re-enter the case number and then process through HyperCite® again.
            </Typography>

            <Typography className="mb-4">
              The Court’s system is down for maintenance or an unplanned outage has occurred.
            </Typography>

            <Typography className="mb-4">
              <b>Remedy:</b> HyperCite® cannot operate when the Court’s system is down for
              maintenance or other reasons. Wait until Court system restores. Visit our homepage for
              system notification.
            </Typography>

            <Typography className="mb-4">HyperCite® is down for maintenance </Typography>

            <Typography className="mb-4">
              <b>Remedy:</b> Check our home page for system notification. If none is visible and you
              believe the system is down, please inform us of the problem here and wait for system
              restore.
            </Typography>

            <Typography className="mb-4" bold>
              2. HyperCite® transformed citations into links but missed one or several citation(s).
            </Typography>

            <Typography className="mb-4">
              In the event HyperCite® creates links but fails to transform one or several
              citation(s), there are two likely causes and several potential remedies.
            </Typography>

            <Typography className="mb-4">
              One, and most likely, you did not cite in the manner the court requires and HyperCite®
              recognizes. (See, supra, <Anchor href="#1">sections (II)(A)(i-iv)</Anchor>.)
            </Typography>

            <Typography className="mb-4" bold>
              Remedies:
            </Typography>

            <Ul className="pl-6">
              <ListItem>
                <Typography>
                  Fix the citation(s) in your word processor, save or re-export to PDF, remember
                  what was wrong with your citation(s) to write it right next time, and re-run
                  HyperCite® processing.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Manually link just the missed citation(s) in your word processor (
                  <a href="https://www.appeals2.az.gov/e-filer/usingHyperlinks.pdf">
                    see Court’s guide
                  </a>
                  ), save or re-export to PDF, remember what was wrong with your citation(s) to
                  write it right next time, and re-run HyperCite® processing.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Manually link just the missed citation(s) in the final PDF with{' '}
                  <Anchor href="https://www.tracker-software.com/product/pdf-xchange-viewer">
                    PDF-Exchange
                  </Anchor>{' '}
                  (free) or{' '}
                  <Anchor href="https://acrobat.adobe.com/us/en/acrobat/acrobat-pro.html">
                    Adobe Pro
                  </Anchor>{' '}
                  (charge), remember what was wrong with your citation(s) to write it right next
                  time, and that is it.
                </Typography>
              </ListItem>
            </Ul>

            <div className="flex items-center justify-center pb-6">
              <div className="w-full">
                <Img fluid={staticData.faqsSection31.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="mb-4">
              Two, you thought you wrote it right, but upon closer inspection, you see a minor
              error(s), like omitted or extraneous punctuation or a missing “COA” from COA-<i>Id</i>
              .
            </Typography>

            <Typography className="mb-4" bold>
              Remedies:
            </Typography>

            <Ul className="pl-6">
              <ListItem>
                <Typography>Same three remedies as above.</Typography>
              </ListItem>
            </Ul>

            <Typography className="mb-4" bold>
              3. The link(s) HyperCite® created do not navigate to the intended destination.
            </Typography>

            <Typography className="mb-4">
              In the event HyperCite® creates a link(s) that do not navigate to the intended
              destination (links go to the wrong document), and you are sure the problem has not
              resulted from human error, please inform HyperCite® of your experience.
            </Typography>

            <Typography className="mb-4" bold>
              4. Error indicated by bounding box
            </Typography>

            <Typography className="mb-4">
              In certain instances, you may see HyperCite® place a gray bounding box around all or
              part of a citation. For example, the citation after processing would appear as OB
              01/20/1992. The gray bounding denotes an error. Typically, the error is the document
              cited cannot be found within the Court’s database. The error is usually resolved by
              correcting the citation.
            </Typography>
          </AccordionItem>

          <AccordionItem title="Troubleshooting Guide Cheat Sheet">
            <table className="table-auto border-collapse border border-gray-300 mb-4">
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>
                    HyperCite® did not transform any citations into hyperlinks.
                  </Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Ul className="pl-6">
                    <ListItem>
                      <Typography>OCR your PDF</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>Double check the case #</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>Wait for Court system restore</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>Wait for HyperCite® system restore</Typography>
                    </ListItem>
                  </Ul>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>
                    HyperCite® transformed citations into links but missed one or several
                    citation(s).
                  </Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>
                    Read user guide sections <Anchor href="#1">(II)(A)(i-iv)</Anchor>. Then:
                  </Typography>
                  <Ul className="pl-6">
                    <ListItem>
                      <Typography>
                        Re-write citation(s) in proper form in your word processer and re-run
                        HyperCite®
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Manually link just the missed cites in your word processor and re-run
                        HyperCite®
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Manually link just the missed cites in PDF with PDF-Exchange (free), Adobe
                        Pro (charge), or otherwise
                      </Typography>
                    </ListItem>
                  </Ul>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-1">
                  <Typography>Error indicated by bounding box</Typography>
                </td>
                <td className="border border-gray-300 p-1">
                  <Typography>Your cite is to a document that does not exist.</Typography>
                  <Ul className="pl-6">
                    <ListItem>
                      <Typography>
                        Re-write citation(s) in proper form in your word processer, save or export
                        to PDF, and re-run HyperCite®
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Manually link just the missed cites in your word processor, save or export
                        to PDF, and re-run HyperCite®
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Manually link just the missed cites in PDF with PDF-Exchange (free), Adobe
                        Pro (charge), or otherwise
                      </Typography>
                    </ListItem>
                  </Ul>
                </td>
              </tr>
            </table>
          </AccordionItem>
        </Accordion>
      </Container>

      <Dialog
        title={<H4>Type and subtype reference table</H4>}
        closable
        maskClosable
        animation="zoom"
        maskAnimation="fade"
        wrapClassName="modal-center"
        closeIcon={<FiX size={32} color={Colors.Blue900} />}
        visible={visible}
        style={{ maxWidth: 1280 }}
        onClose={() => setVisible(false)}>
        <TypesTable />
      </Dialog>
    </PublicRoute>
  );
};

export default Faq;
