import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UserContext } from '../contexts/me';
import { authSelector } from '../selectors/auth';
import { Layout } from './Layout';

export const PublicRoute: FC<RouteComponentProps> = (props) => {
  const { children } = props;
  const isAuthenticated = useSelector(authSelector);

  return (
    <UserContext.Provider value={{ isAuthenticated }}>
      <Layout isAuthenticated={isAuthenticated}>{children}</Layout>
    </UserContext.Provider>
  );
};
