import styled from 'styled-components';

export const Ul = styled.ul<{
  listStyle?: 'roman' | 'disc' | 'latin' | 'none';
}>`
  margin-bottom: 1rem;
  list-style: ${({ listStyle }) => {
    switch (listStyle) {
      case 'latin':
        return 'upper-latin outside';
      case 'roman':
        return 'lower-roman outside';
      case 'none':
        return 'none';
      case 'disc':
      default:
        return 'disc outside';
    }
  }};

  & ul,
  & ol {
    margin-left: 2rem;
  }
`;

export const Ol = styled.ol`
  margin-bottom: 1rem;
  list-style: decimal outside;

  & ul,
  & ol {
    margin-left: 2rem;
  }
`;

export const ListItem = styled.li`
  font-family: 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-size: 12pt;
  line-height: 1.75;
`;
